<template v-slot="props">
  <section>
    <BloomreachPage>
      <TopHeader />
      <br-component component="main" />
      <AppFooter />
    </BloomreachPage>
  </section>
</template>

<script setup lang="ts">
import { CacheTagPrefix, useRedisCache } from '~/composables/useRedisCache'

const { addTags } = useRedisCache()

const route = useRoute()

onServerPrefetch(() => {
  addTags([{ prefix: CacheTagPrefix.View, value: route?.path.replace(/\/+$/, '') }])
})
</script>
